/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';

import Api from '../../../../../libraries/api';


export default function TotalCasesComparisonTab(props) {
  const { children, value, index, ...other } = props;
  const [yearFrom, setYearFrom] = useState(2002);
  const [yearTo, setYearTo] = useState(moment().year());
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(false);
  const minYear = 2002;

  const fetchData = () => {
    setLoading(true);

    Api.get(`/charts/report/yearly_new_instructions?start_year=${yearFrom}&end_year=${yearTo}`)
      .then(resp => {
      if (resp.data) {
        const data = resp.data;
        const labels = Object.keys(data).filter(year => year >= yearFrom && year <= yearTo);
        const values = labels.map(year => data[year]);

        setChartData({
          labels,
          datasets: [
            {
              label: 'New Instructions',
              data: values,
              backgroundColor: '#5a7dfd',
              borderColor: '#5a7dfd',
              borderWidth: 1,
              maxBarThickness: 50,
              barPercentage: 0.5,
            },
          ],
        });
        }
      })
      .finally(() => setLoading(false));
  };

  const handleChangeYearFrom = year => setYearFrom(moment(year).format('YYYY'));
  const handleChangeYearTo = year => setYearTo(moment(year).format('YYYY'));

  useEffect(() => {
    if (yearFrom && yearTo && yearFrom <= yearTo) fetchData();
  }, [yearFrom, yearTo]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        gridLines: { offsetGridLines: false },
        ticks: { stepSize: 1, beginAtZero: true },
      }],
      yAxes: [{
        gridLines: { offsetGridLines: false },
        ticks: { stepSize: 10, beginAtZero: true },
      }],
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => `${context.dataset.label}: ${context.raw}`,
        },
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
        offset: 5,
        clip: false,
        formatter: (value) => (value === 0 ? '' : value),
        color: '#444',
        font: { weight: 'bold' },
      },
    },
  };

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className='table-wrapper'>
          <div className='row align-items-center justify-content-end mb-md-3'>
            <div className="col-md-6 col-lg-4 mr-auto">
              <div className="form-group">
                <label>Year From</label>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    views={['year']}
                    value={moment(yearFrom, 'YYYY').toDate()}
                    onChange={handleChangeYearFrom}
                    format="yyyy"
                    cancelLabel='BATAL'
                    inputVariant='outlined'
                    minDate={moment(minYear, 'YYYY')}
                    maxDate={yearTo ? moment(yearTo, 'YYYY') : moment()}
                    autoOk
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mr-auto">
              <div className="form-group">
                <label>Year To</label>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    views={['year']}
                    value={moment().format('YYYY')}
                    onChange={handleChangeYearTo}
                    format="yyyy"
                    cancelLabel='BATAL'
                    inputVariant='outlined'
                    minDate={moment(yearFrom, 'YYYY')}
                    maxDate={moment().format('YYYY')}
                    autoOk
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
          </div>
          <div className="row align-items-center justify-content-end mb-md-3">
            <div className="col-12">
              <div style={{ minHeight: '500px', height: '60vh' }}>
                {
                  loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', gap: 10 }}>
                      <CircularProgress />
                      <div>Loading data . ..</div>
                    </div>
                  ) : (
                    <>
                      {
                        chartData ? (
                          <Bar  data={chartData} options={options} />
                        ) : (
                          <p>No data available</p>
                        )
                      }
                    </>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
