export const CHART_MONTHS = [
  'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
  'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
];

export const CHART_COLOR_SINGLE = '#5a7dfd';

export const CHART_COLOR_PALETTE = [
  '#5a7dfd',
  '#ff6384',
  '#ff9f00',
  '#1ebead',
  '#36a2eb',
  '#ffcd56',
  '#4bc0c0',
  '#9966ff',
  '#ff9f40',
];

export default {
  CHART_COLOR_SINGLE,
  CHART_COLOR_PALETTE,
};