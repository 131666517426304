/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';

import Api from '../../../../../libraries/api';
import { CHART_COLOR_PALETTE as CHART_COLORS, CHART_MONTHS } from '../../../../../constants/charts';


export default function NewInstructionIssuedApprovalTab(props) {
  const { value, index, ...other } = props;
  const [year, setYear] = useState(new Date().getFullYear());
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(false);
  const minYear = 2002;

  const fetchData = () => {
    setLoading(true);

    Api.get(`/charts/approval/new_instruction_vs_issued_approval?year=${year}`)
      .then((resp) => {
        if (resp.data) {
          const data = resp?.data;
          const months = CHART_MONTHS;
          const datasets = Object.keys(data).map((key, idx) => {
            const chartColor = CHART_COLORS[idx] || `rgba(0, 0, 255, 0.6)`;
            return {
              label: key === 'newInstruction' ? 'New Instruction' : 'Issued Approval',
              data: months.map((month) => data[key][month] || 0),
              backgroundColor: chartColor,
              borderColor: chartColor,
              borderWidth: 1,
              maxBarThickness: 25,
            };
          });

          setChartData({ labels: months, datasets });
        }
      })
      .finally(() => setLoading(false));
  };

  const handleChangeYear = (date) => setYear(moment(date).format('YYYY'));
  
  useEffect(() => {
    fetchData();
  }, [year]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        gridLines: { offsetGridLines: false },
        ticks: { stepSize: 1, beginAtZero: true },
      }],
      yAxes: [{
        gridLines: { offsetGridLines: false },
        ticks: { stepSize: 10, beginAtZero: true },
      }],
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => `${context.dataset.label}: ${context.raw}`,
        },
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
        offset: 10,
        clip: false,
        formatter: (value) => (value === 0 ? '' : value),
        color: '#444',
        font: { weight: 'bold' },
      },
    },
  };

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className="table-wrapper">
          <div className='row align-items-center justify-content-end mb-md-3'>
            <div className="col-md-6 col-lg-4 mr-auto">
              <div className="form-group">
                <label>Year</label>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                  views={['year']}
                  value={moment(year, 'YYYY')}
                  onChange={handleChangeYear}
                  format="yyyy"
                  cancelLabel="BATAL"
                  inputVariant="outlined"
                  minDate={moment(minYear, 'YYYY')}
                  maxDate={moment().endOf('year')}
                  autoOk
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
          </div>
          <div className="row align-items-center justify-content-end mb-md-3">
            <div className="col-12">
              <div style={{ minHeight: '500px', height: '60vh' }}>
                {
                  loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', gap: 10 }}>
                      <CircularProgress />
                      <div>Loading data . ..</div>
                    </div>
                  ) : (
                    <>
                      {
                        chartData ? (
                          <Bar  data={chartData} options={options} />
                        ) : (
                          <p>No data available</p>
                        )
                      }
                    </>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
