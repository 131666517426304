/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';

import Api from '../../../../../libraries/api';
import { CHART_MONTHS } from '../../../../../constants/charts';

const DataTable = ({ data, loading = false, noDataText = 'No data available' }) => {
  const employeeNames = Object.keys(data || {});
  const headerStyle = { fontWeight: 'bold', textTransform: 'uppercase' };
  const boldBodyStyle = { fontWeight: 'bold' };

  return (
    <div style={{ marginBottom: '2rem' }}>
      <Table stickyHeader className='table-list' size='small' >
        <TableHead>
          <TableRow>
            <TableCell style={headerStyle}>Surveyor</TableCell>
            {CHART_MONTHS.map((month) => ( <TableCell key={month} style={headerStyle}>{month}</TableCell>))}
            <TableCell style={headerStyle}>Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={CHART_MONTHS.length + 2} align="center">
                <CircularProgress color="primary" />
              </TableCell>
            </TableRow>
          ) : employeeNames.length === 0 ? (
            <TableRow>
              <TableCell colSpan={CHART_MONTHS.length + 2} align="center">
                {noDataText}
              </TableCell>
            </TableRow>
          ) : (
            employeeNames.map((employee) => {
              const empData = data[employee];
              return (
                <TableRow key={employee}>
                  <TableCell style={boldBodyStyle}>{employee}</TableCell>
                  {CHART_MONTHS.map((month) => (
                    <TableCell key={month}>
                      {empData[month] !== undefined ? empData[month] : 0}
                    </TableCell>
                  ))}
                  <TableCell style={boldBodyStyle}>
                    {empData["total"] !== undefined ? empData["total"] : 0}
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default function TotalApprovalReviewBySurveyorTab(props) {
  const { value, index, ...other } = props;
  const [year, setYear] = useState(new Date().getFullYear());
  const [tableData, setTableData] = useState({});
  const [loading, setLoading] = useState(false);
  const minYear = 2002;

  const fetchData = () => {
    setLoading(true);
    Api.get(`/charts/operation_performance/total_approval_review_by_surveyor?year=${year}`)
      .then((resp) => {
        if (resp.data && resp.data && Array.isArray(resp.data) && resp.data.length > 0) {
          setTableData(resp.data[0]);
        } else {
          setTableData({});
        }
      })
      .finally(() => setLoading(false));
  };

  const handleChangeYear = (date) => setYear(moment(date).format('YYYY'));

  useEffect(() => {
    fetchData();
  }, [year]);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className="table-wrapper">
          <div className="row align-items-center justify-content-end mb-md-3">
            <div className="col-md-6 col-lg-4 mr-auto">
              <div className="form-group">
                <label>Year</label>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    views={['year']}
                    value={moment(year, 'YYYY')}
                    onChange={handleChangeYear}
                    format="yyyy"
                    cancelLabel="BATAL"
                    inputVariant="outlined"
                    minDate={moment(minYear, 'YYYY')}
                    maxDate={moment().endOf('year')}
                    autoOk
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
          </div>
          <div className="row align-items-center justify-content-end mb-md-3">
            <div className="col-12">
              <DataTable data={tableData} loading={loading} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
