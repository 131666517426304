/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import { MenuItem, TextField } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';

import Api from '../../../../../libraries/api';
import { CHART_COLOR_PALETTE as CHART_COLORS } from '../../../../../constants/charts';


export default function NewInstructionPerClientTypeTab(props) {
  const { value, index, ...other } = props;
  const [year, setYear] = useState(new Date().getFullYear());
  const [caseType, setCaseType] = useState('H%26M');
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(false);
  const minYear = 2002;

  const truncateLabel = (label) => label.length > 20 ? label.substring(0, 20) + '...' : label;

  const fetchData = () => {
    setLoading(true);

    Api.get(`/charts/report/most_frequent_clients_per_case_type?year=${year}&case_type=${caseType}`)
      .then((resp) => {
        if (resp?.data && resp?.status === 'success') {
          const data = resp.data;
          const originalLabels = Object.keys(data);
          const truncatedLabels = originalLabels.map((label) => truncateLabel(label));

          const dataset = {
            label: 'New Instructions',
            data: originalLabels.map((client) => data[client]),
            backgroundColor: originalLabels.map((_, idx) => CHART_COLORS[idx % CHART_COLORS.length]),
            borderColor: originalLabels.map((_, idx) => CHART_COLORS[idx % CHART_COLORS.length]),
            borderWidth: 1,
            barThickness: 25,
            maxBarThickness: 25,
          };

          setChartData({ labels: truncatedLabels, datasets: [dataset], fullLabels: originalLabels });
        }
      })
      .finally(() => setLoading(false));
  };

  const handleChangeYear = (date) => setYear(moment(date).format('YYYY'));
  const handleChangeCaseType = (e) => setCaseType(e?.target?.value);

  useEffect(() => {
    fetchData();
  }, [year, caseType]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        gridLines: { offsetGridLines: false },
        ticks: { stepSize: 10, beginAtZero: true },
      }],
      yAxes: [{
        gridLines: { offsetGridLines: false },
        ticks: { stepSize: 1, beginAtZero: true },
      }],
    },
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          const fullLabels = data.fullLabels || data.labels;
          const fullLabel = fullLabels[tooltipItem.index];
          return `${fullLabel}: ${tooltipItem.xLabel}`;
        },
      },
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        offset: 10,
        clip: false,
        formatter: (value) => (value === 0 ? '' : value),
        color: '#444',
        font: { weight: 'bold' },
      },
    },
  };

  const calculatedHeight = chartData ? Math.max(500, chartData.labels.length * (25 + 10)) : 500;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
      <div className="table-wrapper">
        <div className="row align-items-center justify-content-end mb-md-3">
        <div className="col-md-6 col-lg-4 mr-auto">
          <div className="form-group">
            <label>Year</label>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
              views={['year']}
              value={moment(year, 'YYYY')}
              onChange={handleChangeYear}
              format="yyyy"
              cancelLabel="BATAL"
              inputVariant="outlined"
              minDate={moment(minYear, 'YYYY')}
              maxDate={moment().endOf('year')}
              autoOk
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>
        <div className="col-md-6 col-lg-4 mr-auto">
              <div className="form-group">
                <label>Case Type</label>
                <TextField 
                  id="case_type"
                  name="case_type"
                  variant="outlined"
                  onChange={handleChangeCaseType}
                  value={caseType}
                  select
                  fullWidth
                >
                  <MenuItem value="H%26M">H&M</MenuItem>
                  <MenuItem value="P%26I">P&I</MenuItem>
                  <MenuItem value="Shipowner">Shipowner</MenuItem>
                  <MenuItem value='Cargo'>Cargo</MenuItem>
                </TextField>
              </div>
            </div>
        </div>
        <div className="row align-items-center justify-content-end mb-md-3">
          <div className="col-12">
            <div style={{ height: '500px', overflowY: 'auto' }}>
              {
                loading ? (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', gap: 10 }}>
                    <CircularProgress />
                    <div>Loading data . ..</div>
                  </div>
                ) : (
                  <div style={{ height: `${calculatedHeight}px` }}>
                    {
                      chartData ? (
                        <HorizontalBar  data={chartData} options={options} />
                      ) : (
                        <p>No data available</p>
                      )
                    }
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
      )}
    </div>
  );
}
