import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import TotalReportApprovalFinalReviewerTab from './components/TotalReportApprovalFinalReviewerTab';
import TotalAttendedSurveyNewCaseTab from './components/TotalAttendedSurveyNewCaseTab';
import TotalApprovalReviewBySurveyorTab from './components/TotalApprovalReviewBySurveyorTab';
import TotalApprovalReviewByAdminTab from './components/TotalApprovalReviewByAdminTab';


function TabPanel(props) {
  const { children, value, title, index, ...other } = props;

  return (
    <>
      {title && <h2 className="page-title ml-4">{title}</h2>}
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography component="div">{children}</Typography>
          </Box>
        )}
      </div>
    </>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    minWidth: '150px',
  },
  tabRoot: {
    alignItems: 'flex-start',
  },
  tabWrapper: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    textAlign: 'left',
  },
  tabContent: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  breadcrumb: {
    marginBottom: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(3),
  },
}));

export default function OperationPerformanceCharts() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="row main-content">
      <div className="col-12 px-lg-5">
        <h2 className={`page-title ${classes.title}`}>Operation Performance</h2>
        <nav aria-label="breadcrumb" className={classes.breadcrumb}>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li> 
            <li className="breadcrumb-item active" aria-current="page">
              Charts
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Operation Performance
            </li>
          </ol>
        </nav>

        <div className={classes.root}>
          {/* Vertical Tabs */}
          <Tabs
            value={value}
            onChange={handleChange}
            orientation="vertical"
            variant="scrollable"
            indicatorColor="primary"
            textColor="primary"
            className={classes.tabs}
          >
            <Tab label="Total Report & Approval Final Reviewer " {...a11yProps(0)} classes={{ root: classes.tabRoot, wrapper: classes.tabWrapper }} />
            <Tab label="Total Attended Survey per Surveyor New Cases" {...a11yProps(1)} classes={{ root: classes.tabRoot, wrapper: classes.tabWrapper }} />
            <Tab label="Total Approval Review by Surveyor" {...a11yProps(2)} classes={{ root: classes.tabRoot, wrapper: classes.tabWrapper }} />
            <Tab label="Total Approval Review by Admin" {...a11yProps(3)} classes={{ root: classes.tabRoot, wrapper: classes.tabWrapper }} />
          </Tabs>

          {/* Tab Panels */}
          <div className={classes.tabContent}>
            {value === 0 && (
              <TabPanel value={value} index={0} title="Total Report & Approval Final Reviewer ( Year )">
                <TotalReportApprovalFinalReviewerTab />
              </TabPanel>
            )}
            {value === 1 && (
              <TabPanel value={value} index={1} title="Total Attended Survey per Surveyor New Cases ( Year )">
                <TotalAttendedSurveyNewCaseTab />
              </TabPanel>
            )}
            {value === 2 && (
              <TabPanel value={value} index={2} title="Total Approval Review by Surveyor ( Year )">
                <TotalApprovalReviewBySurveyorTab />
              </TabPanel>
            )}
            {value === 3 && (
              <TabPanel value={value} index={3} title="Total Approval Review by Admin ( Year )">
                <TotalApprovalReviewByAdminTab />
              </TabPanel>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

