import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import CircularProgress from '@material-ui/core/CircularProgress';

import Api from '../../../../../libraries/api';
import { CHART_COLOR_PALETTE as CHART_COLORS, CHART_MONTHS } from '../../../../../constants/charts';

export default function TotalCasesComparedLast3YearsTab(props) {
  const { value, index, ...other } = props;
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(false);
  const lastYearsCount = 3;

  const fetchData = () => {
    setLoading(true);

    Api.get(`/charts/report/new_instructions_comparison?years=${lastYearsCount}`)
      .then((resp) => {
        if (resp.data) {
          const data = resp.data;
          const months = CHART_MONTHS;
          const datasets = Object.keys(data).map((year, idx) => {
            // const chartColor = `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255}, 1)`;
            const chartColor = CHART_COLORS[idx];

            return {
              label: year,
              data: months.map((month) => data[year][month] || 0),
              backgroundColor: chartColor,
              borderColor: chartColor,
              borderWidth: 1,
              maxBarThickness: 50,
            };
          });

          setChartData({ labels: months, datasets });
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        gridLines: { offsetGridLines: false },
        ticks: { stepSize: 1, beginAtZero: true },
      }],
      yAxes: [{
        gridLines: { offsetGridLines: false },
        ticks: { stepSize: 10, beginAtZero: true },
      }],
    },
    plugins: {
      tooltip: {
        callbacks: {
        label: (context) => `${context.dataset.label}: ${context.raw}`,
        },
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
        offset: 5,
        clip: false,
        formatter: (value) => (value === 0 ? '' : value),
        color: '#444',
        font: {
        weight: 'bold',
        },
      },
    },
  };

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
    {value === index && (
      <div className="table-wrapper">
        <div className="row align-items-center justify-content-end mb-md-3">
          <div className="col-12">
            <div style={{ minHeight: '500px', height: '60vh' }}>
              {
                loading ? (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', gap: 10 }}>
                    <CircularProgress />
                    <div>Loading data . ..</div>
                  </div>
                ) : (
                  <>
                    {
                      chartData ? (
                        <Bar  data={chartData} options={options} />
                      ) : (
                        <p>No data available</p>
                      )
                    }
                  </>
                )
              }
            </div>
          </div>
        </div>
      </div>
    )}
    </div>
  );
}
