import React from 'react';
import Routes from "./routes";
import configureStore from "./shared/configure-store";
import ChartDataLabels from 'chartjs-plugin-datalabels'; 
import { Chart } from 'react-chartjs-2';

Chart.plugins.register(ChartDataLabels);

const store = configureStore({});


function App() {
    return (
        <Routes store={store}/>
    );
}

export default App;
