/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';

import Api from '../../../../../libraries/api';
import { CHART_MONTHS } from '../../../../../constants/charts';

const DataTable = ({ data, title, loading = false }) => {
  const employeeNames = Object.keys(data || {});
  
  return (
    <div style={{ marginBottom: '2rem' }}>
      <Typography variant="h6" gutterBottom>{title}</Typography>
      <Table stickyHeader className="table-list" size="small">
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>Employee Name</TableCell>
            {CHART_MONTHS.map((month) => (
              <TableCell key={month} style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>{month}</TableCell>
            ))}
            <TableCell style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={CHART_MONTHS.length + 2} align="center"><CircularProgress color="primary" /></TableCell>
            </TableRow>
          ) : employeeNames.length === 0 ? (
            <TableRow>
              <TableCell colSpan={CHART_MONTHS.length + 2} align="center">No data available</TableCell>
            </TableRow>
          ) : (
            employeeNames.map((employee) => {
              const empData = data[employee];
              return (
                <TableRow key={employee}>
                  <TableCell style={{ fontWeight: 'bold' }}>{employee}</TableCell>
                  {CHART_MONTHS.map((month) => (
                    <TableCell key={month}>{empData[month] !== undefined ? empData[month] : 0}</TableCell>
                  ))}
                  <TableCell style={{ fontWeight: 'bold' }}>{empData["total"] !== undefined ? empData["total"] : 0}</TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default function TotalReportApprovalFinalReviewerTab(props) {
  const { value, index, ...other } = props;
  const [year, setYear] = useState(new Date().getFullYear());
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const minYear = 2002;

  const fetchData = () => {
    setLoading(true);
    Api.get(`/charts/operation_performance/total_report_approval_final_reviewer?year=${year}`)
      .then((resp) => {
        if (resp.data && resp.data) {
          setTableData(resp.data);
        } else {
          setTableData({ report: {}, approval: {} });
        }
      })
      .finally(() => setLoading(false));
  };

  const handleChangeYear = (date) => setYear(moment(date).format('YYYY'));

  useEffect(() => {
    fetchData();
  }, [year]);

  const reportData = tableData?.report || {};
  const approvalData = tableData?.approval || {};

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className="table-wrapper">
          <div className="row align-items-center justify-content-end mb-md-3">
            <div className="col-md-6 col-lg-4 mr-auto">
              <div className="form-group">
                <label>Year</label>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    views={['year']}
                    value={moment(year, 'YYYY')}
                    onChange={handleChangeYear}
                    format="yyyy"
                    cancelLabel="BATAL"
                    inputVariant="outlined"
                    minDate={moment(minYear, 'YYYY')}
                    maxDate={moment().endOf('year')}
                    autoOk
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
          </div>
          <div className="row align-items-center justify-content-end mb-md-3">
            <div className="col-12">
              {/* Render the Report and Approval tables */}
              <DataTable data={reportData} title="Report" loading={loading} />
              <DataTable data={approvalData} title="Approval" loading={loading} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
