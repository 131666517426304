import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import TotalCasesComparisonTab from './components/TotalCasesComparisonTab';
import TotalCasesComparedLast3YearsTab from './components/TotalCasesComparedLast3YearsTab';
import NewInstructionPerCaseTypeTab from './components/NewInstructionPerCaseTypeTab';
import TypeCasesPerMonthTab from './components/TypeCasesPerMonthTab';
import MostFrequentClientsTab from './components/MostFrequentClientsTab';
import MostFrequentBrokersTab from './components/MostFrequentBrokersTab';
import NewInstructionPerClientTypeTab from './components/NewInstructionPerClientTypeTab';
import CasesPerCasualtyTypeTab from './components/CasesPerCasualtyTypeTab';
import CasesPerVesselTypeTab from './components/CasesPerVesselTypeTab';
import NewInstructionIssuedReportTab from './components/NewInstructionIssuedReportTab';
import IssuedReportTotalComparisonTab from './components/IssuedReportTotalComparisonTab';


function TabPanel(props) {
  const { children, value, title, index, ...other } = props;

  return (
    <>
      {title && <h2 className="page-title ml-4">{title}</h2>}
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography component="div">{children}</Typography>
          </Box>
        )}
      </div>
    </>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    minWidth: '150px',
  },
  tabRoot: {
    alignItems: 'flex-start',
  },
  tabWrapper: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    textAlign: 'left',
  },
  tabContent: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  breadcrumb: {
    marginBottom: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(3),
  },
}));

export default function ReportCharts() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="row main-content">
      <div className="col-12 px-lg-5">
        <h2 className={`page-title ${classes.title}`}>Report Charts</h2>
        <nav aria-label="breadcrumb" className={classes.breadcrumb}>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li> 
            <li className="breadcrumb-item active" aria-current="page">
              Charts
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Report Charts
            </li>
          </ol>
        </nav>

        <div className={classes.root}>
          {/* Vertical Tabs */}
          <Tabs
            value={value}
            onChange={handleChange}
            orientation="vertical"
            variant="scrollable"
            indicatorColor="primary"
            textColor="primary"
            className={classes.tabs}
          >
            <Tab label="Total Cases Comparison" {...a11yProps(0)} classes={{ root: classes.tabRoot, wrapper: classes.tabWrapper }} />
            <Tab label="Total Cases Compared To Last 3 Years" {...a11yProps(1)} classes={{ root: classes.tabRoot, wrapper: classes.tabWrapper }} />
            <Tab label="New Instructions per Case Type" {...a11yProps(2)} classes={{ root: classes.tabRoot, wrapper: classes.tabWrapper }} />
            <Tab label="Type Cases per Month" {...a11yProps(3)} classes={{ root: classes.tabRoot, wrapper: classes.tabWrapper }} />
            <Tab label="Our Most Frequent Clients" {...a11yProps(4)} classes={{ root: classes.tabRoot, wrapper: classes.tabWrapper }} />
            <Tab label="Our Most Frequent Brokers" {...a11yProps(5)} classes={{ root: classes.tabRoot, wrapper: classes.tabWrapper }} />
            <Tab label="New instruction based on Client Type" {...a11yProps(6)} classes={{ root: classes.tabRoot, wrapper: classes.tabWrapper }} />
            <Tab label="Cases per Casualty Type in a year" {...a11yProps(7)} classes={{ root: classes.tabRoot, wrapper: classes.tabWrapper }} />
            <Tab label="Cases per Vessel Type in a year" {...a11yProps(8)} classes={{ root: classes.tabRoot, wrapper: classes.tabWrapper }} />
            <Tab label="New Instruction & Issued Report" {...a11yProps(9)} classes={{ root: classes.tabRoot, wrapper: classes.tabWrapper }} />
            <Tab label="Total Issued Report Comparison" {...a11yProps(10)} classes={{ root: classes.tabRoot, wrapper: classes.tabWrapper }} />
          </Tabs>

          {/* Tab Panels */}
          <div className={classes.tabContent}>
            {value === 0 && (
              <TabPanel value={value} index={0} title="Total Cases Comparison (Year From - Year To)">
                <TotalCasesComparisonTab />
              </TabPanel>
            )}
            {value === 1 && (
              <TabPanel value={value} index={1} title="Total Cases Compared To Last 3 Years">
                <TotalCasesComparedLast3YearsTab />
              </TabPanel>
            )}
            {value === 2 && (
              <TabPanel value={value} index={2} title="New Instructions per Case Type">
                <NewInstructionPerCaseTypeTab />
              </TabPanel>
            )}
            {value === 3 && (
              <TabPanel value={value} index={3} title="Type Cases per Month">
                <TypeCasesPerMonthTab />
              </TabPanel>
            )}
            {value === 4 && (
              <TabPanel value={value} index={4} title="Our Most Frequent Clients">
                <MostFrequentClientsTab />
              </TabPanel>
            )}
            {value === 5 && (
              <TabPanel value={value} index={5} title="Our Most Frequent Brokers">
                <MostFrequentBrokersTab />
              </TabPanel>
            )}
            {value === 6 && (
              <TabPanel value={value} index={6} title="New instruction based on Client Type">
                <NewInstructionPerClientTypeTab />
              </TabPanel>
            )}
            {value === 7 && (
              <TabPanel value={value} index={7} title="Cases per Casualty Type in a year">
                <CasesPerCasualtyTypeTab />
              </TabPanel>
            )}
            {value === 8 && (
              <TabPanel value={value} index={8} title="Cases per Vessel Type in a year">
                <CasesPerVesselTypeTab />
              </TabPanel>
            )}
            {value === 9 && (
              <TabPanel value={value} index={9} title="New Instruction & Issued Report">
                <NewInstructionIssuedReportTab />
              </TabPanel>
            )}
            {value === 10 && (
              <TabPanel value={value} index={10} title="Total Issued Report Comparison">
                <IssuedReportTotalComparisonTab />
              </TabPanel>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

