import React,{ Component } from 'react';
import PropTypes from "prop-types"
import { withRouter, Link } from 'react-router-dom';
import {bindActionCreators} from "redux";
import {RootActions} from "../../../shared/root-action";
import {connect} from "react-redux";
import AuthHelper from "../../../libraries/auth-helper";
import Logo from "../../../images/logo-small.png";
import Api from "../../../libraries/api";
import user from "../../../images/user.png";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import HomeIcon from '@material-ui/icons/Home';
import AssessmentIcon from '@material-ui/icons/Assessment';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import DashboardIcon from '@material-ui/icons/Dashboard';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import FaceIcon from '@material-ui/icons/Face';
import PieChartIcon from '@material-ui/icons/PieChart';
import ListOutlinedIcon from '@material-ui/icons/ListOutlined';
import PeopleIcon from '@material-ui/icons/People';
import PersonIcon from '@material-ui/icons/Person';
import TuneIcon from '@material-ui/icons/Tune';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import TodayIcon from '@material-ui/icons/Today';
import InboxIcon from '@material-ui/icons/Inbox';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

import {history} from "../../../shared/configure-store";
import _ from "lodash";

class Sidebar extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            menus: [],
            photo: user,
            userName: '',
            userRole: '',
            top: false,
            data: false,
            client: false,
            projects: false,
            surveys: false,
            finance: false,
            charts: false,
            tasks: false,
            clients: false,
            permissions: [],
            role: '',
        };
    }

    componentDidMount() {

        Api.get('/current').then(resp => {

            if(resp.data) {
                let data = resp.data;
                let perms = [];

                if(data.roles.data[0]) {
                    _.forEach(data.roles.data[0].permissions.data, function(v, i){
                        perms.push(v.name)
                    });
                }

                data.permissions = perms;

                AuthHelper.setProfile(data);

                this.props.setProfile(data);

                this.setState({
                    loading: false,
                    role: resp.data.role,
                    photo: resp.data.avatar !== null ? process.env.REACT_APP_API_STORAGE_PATH+resp.data.avatar : user,
                    userName: resp.data.name,
                    userRole: resp.data.roles ? resp.data.roles.data[0].display_name : '',
                    permissions: resp.data.roles ? resp.data.roles.data[0].permissions.data : [],
                });


            }

        }).catch(err => {
            console.log(err);
        });

    }

    checkPermission = (name) => {
        let all = this.state.permissions.filter((item) => item.name === name);
        if(all.length > 0)
            return true;
    };

    handleLogout = () => {

        AuthHelper.logOut();

    };

    openMenu  = (menu) => {

        this.setState({
            [menu]: !this.state[menu],
        });

    };

    handleGo  = (link) => {

        history.push(link);
        this.props.setHideSidebar();

    };

    render() {

        return(
            <aside className={"side-nav " + (this.props.toggle_sidebar_state ? "show" : "")  } >
                {/*<header className="d-none d-lg-block">*/}
                {/*    <nav className="navbar justify-content-center">*/}
                {/*        <Link to="#" className="navbar-brand m-0 p-0">*/}
                {/*            <img src={Logo} alt="abadi" className="logo" />*/}
                {/*        </Link>*/}
                {/*    </nav>*/}
                {/*</header>*/}
                <ul className="nav flex-column">
                    {/*<li className="nav-item profile-picture">*/}
                    {/*    <Link to="/profile">*/}
                    {/*    <img src={this.state.photo} alt="user" className="user-photo mr-2"/>*/}
                    {/*    <div className="content">*/}
                    {/*        <h3>{this.state.userName}</h3>*/}
                    {/*        <span>{this.state.userRole}</span>*/}
                    {/*    </div>*/}
                    {/*    </Link>*/}

                    {/*</li>*/}
                    <List
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                    >
                        <ListItem button onClick={() => this.handleGo('/')}>
                            <ListItemIcon>
                                <HomeIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Home"/>
                        </ListItem>
                        <ListItem button onClick={() => this.handleGo('/timeline-client')}>
                            <ListItemIcon>
                                <HomeIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Client Timeline"/>
                        </ListItem>

                        <ListItem button onClick={() => this.handleGo('/dashboard')}>
                            <ListItemIcon>
                                <DashboardIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Operation Dashboard"/>
                        </ListItem>
                        <ListItem button onClick={() => this.handleGo('/operation-statement')}>
                            <ListItemIcon>
                                <DashboardIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Operation Statement"/>
                        </ListItem>
                        {(this.state.role === 'management' || this.state.role === 'super') &&
                        <ListItem button onClick={() => this.openMenu('top')}>
                            <ListItemIcon>
                                <StarBorderIcon />
                            </ListItemIcon>
                            <ListItemText primary="Top Management" />
                            {this.state.top ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        }

                        <Collapse in={this.state.top} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem  button onClick={() => this.handleGo('/projects?projectStatusReportId=6cb9bc24-606e-46cd-a17b-740398fbf5d7&projectStatusApprovalId=afb9821f-6ed1-4bdc-820b-ee24d3fa136f')}>
                                    <ListItemIcon>
                                        <FaceIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Pak Alif" />
                                </ListItem>
                                <ListItem button onClick={() => this.handleGo('/projects?projectStatusReportId=7ab80398-7935-4991-b32f-88df52cf3cbe&projectStatusApprovalId=d5d44fe1-4606-4543-af6b-93a940be9b06')}>
                                    <ListItemIcon>
                                        <FaceIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Pak Fauzi" />
                                </ListItem>
                                <ListItem button onClick={() => this.handleGo('/projects?surveyorId=fa5ee958-b2ac-11ea-9f52-0242ac180002&projectStatus=On%20Progress')}>
                                    <ListItemIcon>
                                        <FaceIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Pak Pudjo" />
                                </ListItem>
                                <ListItem button onClick={() => this.handleGo('/projects?projectStatusReportId=eb9d6839-dd62-450e-8c96-435d96e05be8')}>
                                    <ListItemIcon>
                                        <FaceIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Pak Faishal" />
                                </ListItem>
                            </List>
                        </Collapse>
                        <ListItem button onClick={() => this.handleGo('/projects')}>
                            <ListItemIcon>
                                <ListOutlinedIcon/>
                            </ListItemIcon>
                            <ListItemText primary="All Project"/>
                        </ListItem>
                        <ListItem button onClick={() => this.openMenu('projects')}>
                            <ListItemIcon>
                                <InboxIcon/>
                            </ListItemIcon>
                            <ListItemText primary="On Progress Report & Approval "/>
                            {this.state.projects ? <ExpandLess/> : <ExpandMore/>}
                        </ListItem>
                        <Collapse in={this.state.projects} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem button
                                          onClick={() => this.handleGo('/projects-filter?typeId=647bbf15-c72a-4cdc-bace-464c5368e325&status=onp')}>
                                    <ListItemIcon>
                                        <ListOutlinedIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Report On Progress "/>
                                </ListItem>
                                <ListItem button
                                          onClick={() => this.handleGo('/projects-filter?typeId=80e0e031-4932-466d-93c5-107ffd361218&status=onp')}>
                                    <ListItemIcon>
                                        <ListOutlinedIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Approval On Progress "/>
                                </ListItem>
                            </List>
                        </Collapse>
                        <ListItem button onClick={() => this.openMenu('surveys')}>
                            <ListItemIcon>
                                <InboxIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Follow Up Survey & Approval  "/>
                            {this.state.surveys ? <ExpandLess/> : <ExpandMore/>}
                        </ListItem>
                        <Collapse in={this.state.surveys} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem button onClick={() => this.handleGo('/projects-filter?survey=687609bd-ef8b-41e4-b866-3a8657467436&typeId=647bbf15-c72a-4cdc-bace-464c5368e325')}>
                                    <ListItemIcon>
                                        <ListOutlinedIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Waiting For PIC "/>
                                </ListItem>
                                <ListItem button
                                          onClick={() => this.handleGo('/projects-filter?survey=53b81920-85cd-44b3-a0b3-a181f1c788ae&typeId=647bbf15-c72a-4cdc-bace-464c5368e325')}>
                                    <ListItemIcon>
                                        <ListOutlinedIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Waiting For Survey Arrangement "/>
                                </ListItem>
                                <ListItem button
                                          onClick={() => this.handleGo('/projects-filter?survey=253fc82e-d620-4256-8be3-b097e7c4175b&typeId=647bbf15-c72a-4cdc-bace-464c5368e325')}>
                                    <ListItemIcon>
                                        <ListOutlinedIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Waiting For Follow Up Survey "/>
                                </ListItem>
                                <ListItem button
                                          onClick={() => this.handleGo('/projects-filter?survey=749d1cf1-f93d-43c5-bb63-ae3325c1c1dc&typeId=647bbf15-c72a-4cdc-bace-464c5368e325')}>
                                    <ListItemIcon>
                                        <ListOutlinedIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Waiting For Documents "/>
                                </ListItem>
                                <ListItem button
                                          onClick={() => this.handleGo('/projects-filter?typeId=80e0e031-4932-466d-93c5-107ffd361218&status=fol')}>
                                    <ListItemIcon>
                                        <ListOutlinedIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Approval Follow Up "/>
                                </ListItem>
                            </List>
                        </Collapse>
                        <hr />
                        <ListItem button onClick={() => this.openMenu('tasks')}>
                            <ListItemIcon>
                                <InboxIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Tasks"/>
                            {this.state.tasks ? <ExpandLess/> : <ExpandMore/>}
                        </ListItem>
                        <Collapse in={this.state.tasks} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem button onClick={() => this.handleGo('/my')}
                                >
                                    <ListItemIcon>
                                        <ListOutlinedIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="My Task"/>
                                </ListItem>
                                <ListItem button onClick={() => this.handleGo('/surveyor')}
                                >
                                    <ListItemIcon>
                                        <ListOutlinedIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Surveyor On Progress"/>
                                </ListItem>
                                <ListItem button  onClick={() => this.handleGo('/admin')}
                                >
                                    <ListItemIcon>
                                        <ListOutlinedIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Admin On Progress"/>
                                </ListItem>
                                <ListItem button
                                          onClick={() => this.handleGo('/projects-filter?commentSevenDays=true')}>
                                    <ListItemIcon>
                                        <ListOutlinedIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Need To Be Update "/>
                                </ListItem>
                                <ListItem button
                                          onClick={() => this.handleGo('/projects-filter?numberOfDays=true')}>
                                    <ListItemIcon>
                                        <ListOutlinedIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Case More Than 3 Months "/>
                                </ListItem>
                            </List>
                        </Collapse>
                        <ListItem button onClick={() => this.handleGo('/to-do-list')}>
                            <ListItemIcon>
                                <TodayIcon/>
                            </ListItemIcon>
                            <ListItemText primary="To Do List"/>
                        </ListItem>
                        <hr />
                        {this.checkPermission('list-invoices') &&
                        <ListItem button onClick={() => this.openMenu('finance')}>
                            <ListItemIcon>
                                <AccountBalanceWalletIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Finance"/>
                            {this.state.finance ? <ExpandLess/> : <ExpandMore/>}
                        </ListItem>
                        }
                        {this.checkPermission('list-invoices') &&
                        <Collapse in={this.state.finance} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem button onClick={() => this.handleGo('/dashboard-finance')}>
                                    <ListItemIcon>
                                        <MonetizationOnIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Finance Dashboard "/>
                                </ListItem>
                                <ListItem button onClick={() => this.handleGo('/invoices')}>
                                    <ListItemIcon>
                                        <MonetizationOnIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Invoices"/>
                                </ListItem>
                                <ListItem button onClick={() => this.handleGo('/pending-issued')}>
                                    <ListItemIcon>
                                        <MonetizationOnIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Pending Issued Invoice"/>
                                </ListItem>
                                <ListItem button
                                          onClick={() => this.handleGo('/pending-payment')}>
                                    <ListItemIcon>
                                        <MonetizationOnIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Pending Payment This Year"/>
                                </ListItem>
                                {/*<ListItem button
                                          onClick={() => this.handleGo('/complete')}>
                                    <ListItemIcon>
                                        <MonetizationOnIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Complete Payment This Year"/>
                                </ListItem>
                                <ListItem button
                                          onClick={() => this.handleGo('/issued-month')}>
                                    <ListItemIcon>
                                        <MonetizationOnIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Issued Invoice This Month"/>
                                </ListItem>
                                <ListItem button
                                          onClick={() => this.handleGo('/issued-week')}>
                                    <ListItemIcon>
                                        <MonetizationOnIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Complete Payment This Month"/>
                                </ListItem>*/}
                            </List>
                        </Collapse>
                        }

                        {/* Charts */}
                        <hr />
                        {(this.state.role === 'management' || this.state.role === 'super') &&
                            <ListItem button onClick={() => this.openMenu('charts')}>
                                <ListItemIcon>
                                    <AssessmentIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Charts"/>
                                {this.state.charts ? <ExpandLess/> : <ExpandMore/>}
                            </ListItem>
                        }
                        {(this.state.role === 'management' || this.state.role === 'super') &&
                            <Collapse in={this.state.charts} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {/* <ListItem button onClick={() => this.handleGo('/project-charts')}>
                                        <ListItemIcon><EqualizerIcon/></ListItemIcon>
                                        <ListItemText primary="Project Charts"/>
                                    </ListItem> */}
                                    <ListItem button onClick={() => this.handleGo('/report-charts')}>
                                        <ListItemIcon><AssessmentIcon/></ListItemIcon>
                                        <ListItemText primary="Report Charts"/>
                                    </ListItem>
                                    <ListItem button onClick={() => this.handleGo('/approval-charts')}>
                                        <ListItemIcon><AssessmentIcon/></ListItemIcon>
                                        <ListItemText primary="Approval Charts"/>
                                    </ListItem>
                                    <ListItem button onClick={() => this.handleGo('/operation-performance-charts')}>
                                        <ListItemIcon><AssessmentIcon/></ListItemIcon>
                                        <ListItemText primary="Operation Performance"/>
                                    </ListItem>
                                </List>
                            </Collapse>
                        }

                        <hr />
                        <ListItem button onClick={() => this.openMenu('clients')}>
                            <ListItemIcon>
                                <InboxIcon/>
                            </ListItemIcon>
                            <ListItemText primary="OS List Client "/>
                            {this.state.clients ? <ExpandLess/> : <ExpandMore/>}
                        </ListItem>
                        <Collapse in={this.state.clients} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem button onClick={() => this.handleGo('/os-clients')}>
                                    <ListItemIcon>
                                        <ListOutlinedIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="OS List Client Dashboard "/>
                                </ListItem>
                                {/*<ListItem button*/}
                                {/*          onClick={() => this.handleGo('/')}>*/}
                                {/*    <ListItemIcon>*/}
                                {/*        <ListOutlinedIcon/>*/}
                                {/*    </ListItemIcon>*/}
                                {/*    <ListItemText primary="OS Per Client "/>*/}
                                {/*</ListItem>*/}
                            </List>
                        </Collapse>
                        <ListItem button onClick={() => this.openMenu('data')}>
                            <ListItemIcon>
                                <PieChartIcon />
                            </ListItemIcon>
                            <ListItemText primary="Data" />
                            {this.state.data ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={this.state.data} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem button onClick={() => this.handleGo('/types')}>
                                    <ListItemIcon>
                                        <ListOutlinedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Project Type" />
                                </ListItem>
                                <ListItem button onClick={() => this.handleGo('/types-detail')}>
                                    <ListItemIcon>
                                        <ListOutlinedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Project Detail Type" />
                                </ListItem>
                                <ListItem button onClick={() => this.handleGo('/vessel-types')}>
                                    <ListItemIcon>
                                        <ListOutlinedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Vessel Type" />
                                </ListItem>
                                <ListItem button onClick={() => this.handleGo('/casualty-types')}>
                                    <ListItemIcon>
                                        <ListOutlinedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Casualty Type" />
                                </ListItem>
                            </List>
                        </Collapse>
                        {this.checkPermission('list-clients') &&
                        <ListItem button onClick={() => this.openMenu('client')}>
                            <ListItemIcon>
                                <PeopleIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Clients"/>
                            {this.state.client ? <ExpandLess/> : <ExpandMore/>}
                        </ListItem>
                        }

                        <Collapse in={this.state.client} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem button onClick={() => this.handleGo('/clients')}>
                                    <ListItemIcon>
                                        <PersonOutlinedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Clients" />
                                </ListItem>
                                <ListItem button onClick={() => this.handleGo('/clients/type/Underwriter')}>
                                    <ListItemIcon>
                                        <PersonOutlinedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Underwriter" />
                                </ListItem>

                                <ListItem button onClick={() => this.handleGo('/clients/type/Broker')}>
                                    <ListItemIcon>
                                        <PersonOutlinedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Broker" />
                                </ListItem>
                                <ListItem button onClick={() => this.handleGo('/clients/type/Shipowner')}>
                                    <ListItemIcon>
                                        <PersonOutlinedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Shipowner" />
                                </ListItem>
                                <ListItem button onClick={() => this.handleGo('/clients/type/Adjuster')}>
                                    <ListItemIcon>
                                        <PersonOutlinedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Adjuster" />
                                </ListItem>
                            </List>
                        </Collapse>

                        <hr />
                        <ListItem button onClick={() => this.handleGo('/profile')}>
                            <ListItemIcon>
                                <AssignmentIndIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Edit Profile"/>
                        </ListItem>
                        <hr />

                        {this.checkPermission('list-users') &&
                        <ListItem button onClick={() => this.handleGo('/users')}>
                            <ListItemIcon>
                                <PersonIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Admin"/>
                        </ListItem>
                        }

                        {this.checkPermission('list-roles') &&
                        <ListItem button onClick={() => this.handleGo('/roles')}>
                            <ListItemIcon>
                                <TuneIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Roles"/>
                        </ListItem>
                        }
                        {/* {(this.state.role === 'management' || this.state.role === 'super') &&
                        <ListItem button onClick={() => this.handleGo('/charts')}>
                            <ListItemIcon>
                                <AssessmentIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Chart"/>
                        </ListItem>
                        } */}

                     {/*   {this.checkPermission('view-dashboards') &&
                        <ListItem button onClick={() => this.handleGo('/dashboard')}>
                            <ListItemIcon>
                                <DashboardIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Dashboard"/>
                        </ListItem>
                        }

                        <hr />
                        {this.checkPermission('list-projects') &&
                        <ListItem button onClick={() => this.openMenu('projects')}>
                            <ListItemIcon>
                                <InboxIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Projects"/>
                            {this.state.projects ? <ExpandLess/> : <ExpandMore/>}
                        </ListItem>
                        }
                        {this.checkPermission('list-projects') &&
                        <Collapse in={this.state.projects} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem button onClick={() => this.handleGo('/projects')}>
                                    <ListItemIcon>
                                        <ListOutlinedIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="All Project"/>
                                </ListItem>
                                <ListItem button
                                          onClick={() => this.handleGo('/projects-filter?commentSevenDays=true')}>
                                    <ListItemIcon>
                                        <ListOutlinedIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Need To Be Update"/>
                                </ListItem>
                                <ListItem button
                                          onClick={() => this.handleGo('/projects-filter?typeId=647bbf15-c72a-4cdc-bace-464c5368e325&status=onp')}>
                                    <ListItemIcon>
                                        <ListOutlinedIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Report On Progress "/>
                                </ListItem>
                                <ListItem button
                                          onClick={() => this.handleGo('/projects-filter?typeId=80e0e031-4932-466d-93c5-107ffd361218&status=onp')}>
                                    <ListItemIcon>
                                        <ListOutlinedIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Approval On Progress "/>
                                </ListItem>
                                <ListItem button
                                          onClick={() => this.handleGo('/projects-filter?typeId=647bbf15-c72a-4cdc-bace-464c5368e325&status=fol')}
                                          >
                                    <ListItemIcon>
                                        <ListOutlinedIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Report Follow Up "/>
                                </ListItem>
                                <ListItem button
                                          onClick={() => this.handleGo('/projects-filter?typeId=80e0e031-4932-466d-93c5-107ffd361218&status=fol')}
                                        >
                                    <ListItemIcon>
                                        <ListOutlinedIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Approval Follow Up "/>
                                </ListItem>
                            </List>
                        </Collapse>
                        }*/}




                       {/* {this.checkPermission('list-events') &&
                        <ListItem button onClick={() => this.handleGo('/events')}>
                            <ListItemIcon>
                                <CalendarTodayIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Events"/>
                        </ListItem>
                        }
                        <hr />
*/}

                        <ListItem button onClick={this.handleLogout}>
                            <ListItemIcon>
                                <ExitToAppIcon />
                            </ListItemIcon>
                            <ListItemText primary="Logout" />
                        </ListItem>

                    </List>

                    </ul>
            </aside>

        )

    }

}

const mapStateToProps = (state) => {

    return {

        toggle_sidebar_state: state.toggle_sidebar_state,

        profile_state: state.profile_state,

        permission_state: state.permission_state,


    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar));